<template>
  <v-container>
    <v-row>
      <v-col class="col-6" align="center">
        <p class="text-h2">4<span class="red--text text--darken-1">P</span></p>
        <span>企業視点の要素</span>
      </v-col>
      <v-col class="col-6" align="center">
        <p class="text-h2">4<span class="blue--text text--darken-1">C</span></p>
        <span>顧客視点の要素</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
	    <v-card>
	    <v-card-text>
        <v-row>
          <v-col>
            <span class="text-h4 mr-1"><span class="red--text text--darken-1">P</span>roduct</span>製品
            <v-textarea 
              v-model="fourPsFourCs.product"
              outlined
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-h4 mr-1"><span class="red--text text--darken-1">P</span>rice</span>価格
            <v-textarea 
              v-model="fourPsFourCs.price"
              outlined
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-h4 mr-1"><span class="red--text text--darken-1">P</span>lace</span>流通
            <v-textarea 
              v-model="fourPsFourCs.place"
              outlined
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-h4 mr-1"><span class="red--text text--darken-1">P</span>romotion</span>販促
            <v-textarea 
              v-model="fourPsFourCs.promotion"
              outlined
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
	    </v-card-text>
	    </v-card>
      </v-col>
      <v-col class="col-6">
	    <v-card>
	    <v-card-text>
        <v-row>
          <v-col>
            <span class="text-h4 mr-1"><span class="blue--text text--darken-1">C</span>ustomer Value</span>顧客価値
            <v-textarea 
              v-model="fourPsFourCs.customerValue"
              outlined
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-h4 mr-1"><span class="blue--text text--darken-1">C</span>ost</span>顧客にとっての経費
            <v-textarea 
              v-model="fourPsFourCs.cost"
              outlined
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-h4 mr-1"><span class="blue--text text--darken-1">C</span>onvenience</span>顧客利便性
            <v-textarea 
              v-model="fourPsFourCs.convenience"
              outlined
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-h4 mr-1"><span class="blue--text text--darken-1">C</span>ommunication</span>顧客とのコミュニケーション
            <v-textarea 
              v-model="fourPsFourCs.communication"
              outlined
              :clearable="!readonly"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
	    </v-card-text>
	    </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  props: ['value', 'readonly'],
  data () {
    return {
      fourPsFourCs: {
        product: null,
        price: null,
        place: null,
        promotion: null,
        customerValue: null,
        cost: null,
        convenience: null,
        communication: null,
      },
    }
  },
  created(){
    if(this.value) {
      this.fourPsFourCs = this.value
    }
  },
  watch: {
    value(val) {
      this.fourPsFourCs = val
    },
  }
}
</script>
