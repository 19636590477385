<template>
  <v-expansion-panels v-if="isShow" :value="expansionPanelsValues" multiple tile>
    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        id="sdb-swot-section"
        label="SWOT分析"
        :status="swot.inputStatusSwot"
        @finish="swot.inputStatusSwot = $event ? 'done': isEmpty_swot() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <swot-analysis v-model="swot" :readonly="!hasWritePermission || swot.inputStatusSwot == 'done'"></swot-analysis>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <sheet-info v-model="sheetInfoSwot" class="mb-5" @mounted="sheetInfo_mounted_swot = true"></sheet-info>

    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        id="sdb-threeCs-section"
        label="3C分析"
        :status="threeCs.inputStatusThreeCs"
        @finish="threeCs.inputStatusThreeCs = $event ? 'done': isEmpty_threeCs() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <three-cs-analysis v-model="threeCs" :readonly="!hasWritePermission || threeCs.inputStatusThreeCs == 'done'"></three-cs-analysis>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <sheet-info v-model="sheetInfoThreeCs" class="mb-5" @mounted="sheetInfo_mounted_threeCs = true"></sheet-info>

    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        id="sdb-stp-section"
        label="STP分析"
        :status="stp.inputStatusStp"
        @finish="stp.inputStatusStp = $event ? 'done': isEmpty_stp() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <stp-analysis v-model="stp" :readonly="!hasWritePermission || stp.inputStatusStp == 'done'"></stp-analysis>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <sheet-info v-model="sheetInfoStp" class="mb-5" @mounted="sheetInfo_mounted_stp = true"></sheet-info>

    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        id="sdb-fiveForces-section"
        label="5FORCE分析"
        :status="fiveForces.inputStatusFiveForces"
        @finish="fiveForces.inputStatusFiveForces = $event ? 'done': isEmpty_fiveForces() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <five-forces-analysis v-model="fiveForces" :readonly="!hasWritePermission || fiveForces.inputStatusFiveForces == 'done'"></five-forces-analysis>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <sheet-info v-model="sheetInfoFiveForces" class="mb-5" @mounted="sheetInfo_mounted_fiveForces = true"></sheet-info>

    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        id="sdb-pest-section"
        label="PEST分析"
        :status="pest.inputStatusPest"
        @finish="pest.inputStatusPest = $event ? 'done': isEmpty_pest() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <pest-analysis v-model="pest" :readonly="!hasWritePermission || pest.inputStatusPest == 'done'"></pest-analysis>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <sheet-info v-model="sheetInfoPest" class="mb-5" @mounted="sheetInfo_mounted_pest = true"></sheet-info>

    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        id="sdb-fourPsFourCs-section"
        label="4P4C分析"
        :status="fourPsFourCs.inputStatusFourPsFourCs"
        @finish="fourPsFourCs.inputStatusFourPsFourCs = $event ? 'done': isEmpty_fourPsFourCs() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <four-ps-four-cs-analysis v-model="fourPsFourCs" :readonly="!hasWritePermission || fourPsFourCs.inputStatusFourPsFourCs == 'done'"></four-ps-four-cs-analysis>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <sheet-info v-model="sheetInfoFourPsFourCs" class="mb-5" @mounted="sheetInfo_mounted_fourPsFourCs = true"></sheet-info>

    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        id="sdb-fourPsComparison-section"
        label="4P競合比較分析"
        :status="fourPsComparison.inputStatusFourPsComparison"
        @finish="fourPsComparison.inputStatusFourPsComparison = $event ? 'done': isEmpty_fourPsComparison() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <four-ps-comparison-analysis v-model="fourPsComparison" :readonly="!hasWritePermission || fourPsComparison.inputStatusFourPsComparison == 'done'"></four-ps-comparison-analysis>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <sheet-info v-model="sheetInfoFourPsComparison" class="mb-5" @mounted="sheetInfo_mounted_fourPsComparison = true"></sheet-info>

    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        id="sdb-businessModelCanvas-section"
        label="ビジネスモデルキャンバス"
        :status="businessModelCanvas.inputStatusBusinessModelCanvas"
        @finish="businessModelCanvas.inputStatusBusinessModelCanvas = $event ? 'done': isEmpty_businessModelCanvas() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <business-model-canvas v-model="businessModelCanvas" :readonly="!hasWritePermission || businessModelCanvas.inputStatusBusinessModelCanvas == 'done'"></business-model-canvas>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <sheet-info v-model="sheetInfoBusinessModelCanvas" class="bottommost-section" @mounted="sheetInfo_mounted_businessModelCanvas = true"></sheet-info>

  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

import VExpansionPanelHeaderWithLabel from '../atoms/VExpansionPanelHeaderWithLabel';
import SwotAnalysis from '../organisms/SwotAnalysis';
import ThreeCsAnalysis from '../organisms/ThreeCsAnalysis';
import StpAnalysis from '../organisms/StpAnalysis';
import FiveForcesAnalysis from '../organisms/FiveForcesAnalysis';
import PestAnalysis from '../organisms/PestAnalysis';
import FourPsFourCsAnalysis from '../organisms/FourPsFourCsAnalysis';
import FourPsComparisonAnalysis from '../organisms/FourPsComparisonAnalysis';
import BusinessModelCanvas from '../organisms/BusinessModelCanvas';
import SheetInfo from '../organisms/SheetInfo';

export default {
  components: {
    VExpansionPanelHeaderWithLabel,
    SwotAnalysis,
    ThreeCsAnalysis,
    StpAnalysis,
    FiveForcesAnalysis,
    PestAnalysis,
    FourPsFourCsAnalysis,
    FourPsComparisonAnalysis,
    BusinessModelCanvas,
    SheetInfo,
  },

  props: ['vSwot', 'vThreeCs', 'vStp', 'vFiveForces', 'vPest', 'vFourPsFourCs', 'vFourPsComparison', 'vBusinessModelCanvas'],
  data() {
    return {
      expansionPanelsValues: [0, 1, 2, 3, 4, 5, 6, 7],

      //### データ関連 ###
      dataInit: false,
      needUpdateSwot: false,  //SWOT分析更新API呼出の要否フラグ
      needUpdateThreeCs: false,  //3C分析更新API呼出の要否フラグ
      needUpdateStp: false,  //STP分析更新API呼出の要否フラグ
      needUpdateFiveForces: false,  //5force分析更新API呼出の要否フラグ
      needUpdatePest: false,  //PEST分析更新API呼出の要否フラグ
      needUpdateFourPsFourCs: false,  //4P4C分析更新API呼出の要否フラグ
      needUpdateFourPsComparison: false,  //4P競合比較分析更新API呼出の要否フラグ
      needUpdateBusinessModelCanvas: false,  //ビジネスモデルキャンバス更新API呼出の要否フラグ

      swotData: null, //SWOT分析セクションのデータ
      threeCsData: null, //3C分析セクションのデータ
      stpData: null, //STP分析セクションのデータ
      fiveForcesData: null, //5force分析セクションのデータ
      pestData: null, //PEST分析セクションのデータ
      fourPsFourCsData: null, //4P4C分析セクションのデータ
      fourPsComparisonData: null, //4P競合比較分析セクションのデータ
      businessModelCanvasData: null, //ビジネスモデルキャンバスセクションのデータ
      swot: null, //SWOT分析セクションのデータ
      threeCs: null, //3C分析セクションのデータ
      stp: null, //STP分析セクションのデータ
      fiveForces: null, //5force分析セクションのデータ
      pest: null, //PEST分析セクションのデータ
      fourPsFourCs: null, //4P4C分析セクションのデータ
      fourPsComparison: null, //4P競合比較分析セクションのデータ
      businessModelCanvas: null, //ビジネスモデルキャンバスセクションのデータ

      sheetInfoSwot: null, //シートの作成者情報
      sheetInfoThreeCs: null, //シートの作成者情報
      sheetInfoStp: null, //シートの作成者情報
      sheetInfoFiveForces: null, //シートの作成者情報
      sheetInfoPest: null, //シートの作成者情報
      sheetInfoFourPsFourCs: null, //シートの作成者情報
      sheetInfoFourPsComparison: null, //シートの作成者情報
      sheetInfoBusinessModelCanvas: null, //シートの作成者情報

      //### 未入力・編集中チェック ###
      timerObj: null, //タイマー実行のオブジェクト(タイマークリアの為、保持する)
      timerObj2: null, //タイマー実行のオブジェクト(データ初期化確認用)
      need_notInput_swot: false,  //SWOT分析セクションの未入力・編集中チェック要否フラグ
      need_notInput_threeCs: false,  //3C分析セクションの未入力・編集中チェック要否フラグ
      need_notInput_stp: false,  //STP分析セクションの未入力・編集中チェック要否フラグ
      need_notInput_fiveForces: false,  //5force分析セクションの未入力・編集中チェック要否フラグ
      need_notInput_pest: false,  //PEST分析セクションの未入力・編集中チェック要否フラグ
      need_notInput_fourPsFourCs: false,  //4P4C分析セクションの未入力・編集中チェック要否フラグ
      need_notInput_fourPsComparison: false,  //4P競合比較分析セクションの未入力・編集中チェック要否フラグ
      need_notInput_businessModelCanvas: false,  //ビジネスモデルキャンバスセクションの未入力・編集中チェック要否フラグ

      sheetInfo_mounted_swot: false, 
      sheetInfo_mounted_threeCs: false, 
      sheetInfo_mounted_stp: false, 
      sheetInfo_mounted_fiveForces: false, 
      sheetInfo_mounted_pest: false, 
      sheetInfo_mounted_fourPsFourCs: false, 
      sheetInfo_mounted_fourPsComparison: false, 
      sheetInfo_mounted_businessModelCanvas: false, 
    };
  },

  computed: {
    ...mapGetters([
      'hasWritePermission',
      'userInfo',
    ]),
    needUpdate() {
      return this.needUpdateSwot || this.needUpdateThreeCs || this.needUpdateStp || this.needUpdateFiveForces || this.needUpdatePest || this.needUpdateFourPsFourCs || this.needUpdateFourPsComparison || this.needUpdateBusinessModelCanvas
    },
    isShow() {
      return this.swot && this.threeCs && this.stp && this.fiveForces && this.pest && this.fourPsFourCs && this.fourPsComparison && this.businessModelCanvas;
    },
  },

  created() {
    //データ初期化
    if(this.vSwot) {
      this.swotData = this.vSwot
      this.initSectionSwot(this.vSwot);
    }
    if(this.vThreeCs) {
      this.threeCsData = this.vThreeCs
      this.initSectionThreeCs(this.vThreeCs);
    }
    if(this.vStp) {
      this.stpData = this.vStp
      this.initSectionStp(this.vStp);
    }
    if(this.vFiveForces) {
      this.fiveForcesData = this.vFiveForces
      this.initSectionFiveForces(this.vFiveForces);
    }
    if(this.vPest) {
      this.pestData = this.vPest
      this.initSectionPest(this.vPest);
    }
    if(this.vFourPsFourCs) {
      this.fourPsFourCsData = this.vFourPsFourCs
      this.initSectionFourPsFourCs(this.vFourPsFourCs);
    }
    if(this.vFourPsComparison) {
      this.fourPsComparisonData = this.vFourPsComparison
      this.initSectionFourPsComparison(this.vFourPsComparison);
    }
    if(this.vBusinessModelCanvas) {
      this.businessModelCanvasData = this.vBusinessModelCanvas
      this.initSectionBusinessModelCanvas(this.vBusinessModelCanvas);
    }

    //未入力・編集中チェックタイマー登録
    this.timerObj = setInterval(() => {
      if(document.visibilityState != 'visible') return

      //SWOT分析セクションのチェック
      if(this.swot && this.swot.inputStatusSwot != 'done' && this.need_notInput_swot) {

        if(this.isEmpty_swot()) {
          if(this.swot.inputStatusSwot != 'notInput')
            this.swot.inputStatusSwot = 'notInput'
        } else {
          if(this.swot.inputStatusSwot != 'edit')
            this.swot.inputStatusSwot = 'edit'
        }
        this.need_notInput_swot = false
      }
      //3C分析セクションのチェック
      if(this.threeCs && this.threeCs.inputStatusThreeCs != 'done' && this.need_notInput_threeCs) {

        if(this.isEmpty_threeCs()) {
          if(this.threeCs.inputStatusThreeCs != 'notInput')
            this.threeCs.inputStatusThreeCs = 'notInput'
        } else {
          if(this.threeCs.inputStatusThreeCs != 'edit')
            this.threeCs.inputStatusThreeCs = 'edit'
        }
        this.need_notInput_threeCs = false
      }
      //STP分析セクションのチェック
      if(this.stp && this.stp.inputStatusStp != 'done' && this.need_notInput_stp) {

        if(this.isEmpty_stp()) {
          if(this.stp.inputStatusStp != 'notInput')
            this.stp.inputStatusStp = 'notInput'
        } else {
          if(this.stp.inputStatusStp != 'edit')
            this.stp.inputStatusStp = 'edit'
        }
        this.need_notInput_stp = false
      }
      //5force分析セクションのチェック
      if(this.fiveForces && this.fiveForces.inputStatusFiveForces != 'done' && this.need_notInput_fiveForces) {

        if(this.isEmpty_fiveForces()) {
          if(this.fiveForces.inputStatusFiveForces != 'notInput')
            this.fiveForces.inputStatusFiveForces = 'notInput'
        } else {
          if(this.fiveForces.inputStatusFiveForces != 'edit')
            this.fiveForces.inputStatusFiveForces = 'edit'
        }
        this.need_notInput_fiveForces = false
      }
      //PEST分析セクションのチェック
      if(this.pest && this.pest.inputStatusPest != 'done' && this.need_notInput_pest) {

        if(this.isEmpty_pest()) {
          if(this.pest.inputStatusPest != 'notInput')
            this.pest.inputStatusPest = 'notInput'
        } else {
          if(this.pest.inputStatusPest != 'edit')
            this.pest.inputStatusPest = 'edit'
        }
        this.need_notInput_pest = false
      }
      //4P4C分析セクションのチェック
      if(this.fourPsFourCs && this.fourPsFourCs.inputStatusFourPsFourCs != 'done' && this.need_notInput_fourPsFourCs) {

        if(this.isEmpty_fourPsFourCs()) {
          if(this.fourPsFourCs.inputStatusFourPsFourCs != 'notInput')
            this.fourPsFourCs.inputStatusFourPsFourCs = 'notInput'
        } else {
          if(this.fourPsFourCs.inputStatusFourPsFourCs != 'edit')
            this.fourPsFourCs.inputStatusFourPsFourCs = 'edit'
        }
        this.need_notInput_fourPsFourCs = false
      }
      //4P競合比較分析セクションのチェック
      if(this.fourPsComparison && this.fourPsComparison.inputStatusFourPsComparison != 'done' && this.need_notInput_fourPsComparison) {

        if(this.isEmpty_fourPsComparison()) {
          if(this.fourPsComparison.inputStatusFourPsComparison != 'notInput')
            this.fourPsComparison.inputStatusFourPsComparison = 'notInput'
        } else {
          if(this.fourPsComparison.inputStatusFourPsComparison != 'edit')
            this.fourPsComparison.inputStatusFourPsComparison = 'edit'
        }
        this.need_notInput_fourPsComparison = false
      }
      //ビジネスモデルキャンバス分析セクションのチェック
      if(this.businessModelCanvas && this.businessModelCanvas.inputStatusBusinessModelCanvas != 'done' && this.need_notInput_businessModelCanvas) {

        if(this.isEmpty_businessModelCanvas()) {
          if(this.businessModelCanvas.inputStatusBusinessModelCanvas != 'notInput')
            this.businessModelCanvas.inputStatusBusinessModelCanvas = 'notInput'
        } else {
          if(this.businessModelCanvas.inputStatusBusinessModelCanvas != 'edit')
            this.businessModelCanvas.inputStatusBusinessModelCanvas = 'edit'
        }
        this.need_notInput_businessModelCanvas = false
      }
    }, 1000)

    //データ初期化確認用タイマー
    this.timerObj2 = setInterval(() => {
      if(this.sheetInfo_mounted_swot && this.sheetInfo_mounted_threeCs && this.sheetInfo_mounted_stp && this.sheetInfo_mounted_fiveForces && this.sheetInfo_mounted_pest && this.sheetInfo_mounted_fourPsFourCs && this.sheetInfo_mounted_fourPsComparison && this.sheetInfo_mounted_businessModelCanvas ) {
        //更新者など描画完了で、データ初期化完了とする
        this.dataInit = true
        clearInterval(this.timerObj2);
      }
    }, 1000)
  },
  mounted() {
    //初期スクロール位置へ移動
    const initialScrollTarget = this.$store.getters.initialScrollTarget
    if(initialScrollTarget) {
      this.$vuetify.goTo(initialScrollTarget, {
        container: '#StrategyEdit_top',
        offset: 100,
        duration: 500
      })
      this.$store.commit('initialScrollTarget', null)
    }
  },
  beforeDestroy() {
    clearInterval(this.timerObj);
    if(this.timerObj2) clearInterval(this.timerObj2);
  },

  methods: {
    ...mapActions(['updateSwot', 'updateThreeCs', 'updateStp', 'updateFiveForces', 'updatePest', 'updateFourPsFourCs', 'updateFourPsComparison', 'updateBusinessModelCanvas']),

    //デフォルトのサーバー同期処理
    updateDefault(force){
      if (force || this.needUpdateSwot) {
        this.sheetInfoSwot.updatedUser = this.userInfo.userId
        this.sheetInfoSwot.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfoSwot.updatedAt = new Date()

        this.updateSwot(this.swotData)
        this.needUpdateSwot = false
      }
      if (force || this.needUpdateThreeCs) {
        this.sheetInfoThreeCs.updatedUser = this.userInfo.userId
        this.sheetInfoThreeCs.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfoThreeCs.updatedAt = new Date()

        this.updateThreeCs(this.threeCsData)
        this.needUpdateThreeCs = false
      }
      if (force || this.needUpdateStp) {
        this.sheetInfoStp.updatedUser = this.userInfo.userId
        this.sheetInfoStp.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfoStp.updatedAt = new Date()

        this.updateStp(this.stpData)
        this.needUpdateStp = false
      }
      if (force || this.needUpdateFiveForces) {
        this.sheetInfoFiveForces.updatedUser = this.userInfo.userId
        this.sheetInfoFiveForces.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfoFiveForces.updatedAt = new Date()

        this.updateFiveForces(this.fiveForcesData)
        this.needUpdateFiveForces = false
      }
      if (force || this.needUpdatePest) {
        this.sheetInfoPest.updatedUser = this.userInfo.userId
        this.sheetInfoPest.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfoPest.updatedAt = new Date()

        this.updatePest(this.pestData)
        this.needUpdatePest = false
      }
      if (force || this.needUpdateFourPsFourCs) {
        this.sheetInfoFourPsFourCs.updatedUser = this.userInfo.userId
        this.sheetInfoFourPsFourCs.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfoFourPsFourCs.updatedAt = new Date()

        this.updateFourPsFourCs(this.fourPsFourCsData)
        this.needUpdateFourPsFourCs = false
      }
      if (force || this.needUpdateFourPsComparison) {
        this.sheetInfoFourPsComparison.updatedUser = this.userInfo.userId
        this.sheetInfoFourPsComparison.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfoFourPsComparison.updatedAt = new Date()

        this.updateFourPsComparison(this.fourPsComparisonData)
        this.needUpdateFourPsComparison = false
      }
      if (force || this.needUpdateBusinessModelCanvas) {
        this.sheetInfoBusinessModelCanvas.updatedUser = this.userInfo.userId
        this.sheetInfoBusinessModelCanvas.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfoBusinessModelCanvas.updatedAt = new Date()

        this.updateBusinessModelCanvas(this.businessModelCanvasData)
        this.needUpdateBusinessModelCanvas = false
      }
    },
    finishAll() {
      this.swot.inputStatusSwot = 'done'
      this.threeCs.inputStatusThreeCs = 'done'
      this.stp.inputStatusStp = 'done'
      this.fiveForces.inputStatusFiveForces = 'done'
      this.pest.inputStatusPest = 'done'
      this.fourPsFourCs.inputStatusFourPsFourCs = 'done'
      this.fourPsComparison.inputStatusFourPsComparison  = 'done'
      this.businessModelCanvas.inputStatusBusinessModelCanvas = 'done'
    },

    //SWOT分析セクションデータの初期化
    initSectionSwot(val) {
      if(val.swot && Object.keys(val.swot).length > 0) {
        this.swot = val.swot
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfoSwot = val.sheetInfo
      }
    },

    //3C分析セクションデータの初期化
    initSectionThreeCs(val) {
      if(val.threeCs && Object.keys(val.threeCs).length > 0) {
        this.threeCs = val.threeCs
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfoThreeCs = val.sheetInfo
      }
    },

    //STP分析セクションデータの初期化
    initSectionStp(val) {
      if(val.stp && Object.keys(val.stp).length > 0) {
        this.stp = val.stp
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfoStp = val.sheetInfo
      }
    },

    //5force分析セクションデータの初期化
    initSectionFiveForces(val) {
      if(val.fiveForces && Object.keys(val.fiveForces).length > 0) {
        this.fiveForces = val.fiveForces
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfoFiveForces = val.sheetInfo
      }
    },

    //PEST分析セクションデータの初期化
    initSectionPest(val) {
      if(val.pest && Object.keys(val.pest).length > 0) {
        this.pest = val.pest
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfoPest = val.sheetInfo
      }
    },

    //4P4C分析セクションデータの初期化
    initSectionFourPsFourCs(val) {
      if(val.fourPsFourCs && Object.keys(val.fourPsFourCs).length > 0) {
        this.fourPsFourCs = val.fourPsFourCs
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfoFourPsFourCs = val.sheetInfo
      }
    },

    //4P競合比較分析セクションデータの初期化
    initSectionFourPsComparison(val) {
      if(val.fourPsComparison && Object.keys(val.fourPsComparison).length > 0) {
        this.fourPsComparison = val.fourPsComparison
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfoFourPsComparison = val.sheetInfo
      }
    },

    //ビジネスモデルキャンバスセクションデータの初期化
    initSectionBusinessModelCanvas(val) {
      if(val.businessModelCanvas && Object.keys(val.businessModelCanvas).length > 0) {
        this.businessModelCanvas = val.businessModelCanvas
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfoBusinessModelCanvas = val.sheetInfo
      }
    },

    //SWOT分析セクションの未入力チェック
    isEmpty_swot() {
      if(!this.swot) return true

      const swot = this.swot
      const swotMatrix = swot.swotMatrix
      let ret = false

      //各質問項目をチェック
      if (swot.swotSummary) {
        return false
      }

      const questionKeys = ['swotStrengths', 'swotWeakness', 'swotOpportunity', 'swotThreat', 'swotStrengthsOpportunity', 'swotWeaknessOpportunity', 'swotStrengthsThreat', 'swotWeaknessThreat']
      ret = questionKeys.some(i => swotMatrix[i])
       if(ret) return false

      return true
    },

    //3C分析セクションの未入力チェック
    isEmpty_threeCs() {
      if(!this.threeCs) return true

      const threeCs = this.threeCs
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['customerViewpoint', 'competitorViewpoint', 'companyViewpoint','threeCsSummary']
      ret = questionKeys.some(i => threeCs[i])
       if(ret) return false

      return true
    },

    //STP分析セクションの未入力チェック
    isEmpty_stp() {
      if(!this.stp) return true

      const stp = this.stp
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['stpSegmentation', 'stpTarget', 'stpPositioningmap','stpSummary']
      ret = questionKeys.some(i => stp[i])
       if(ret) return false

      return true
    },

    //5force分析セクションの未入力チェック
    isEmpty_fiveForces() {
      if(!this.fiveForces) return true

      const fiveForces = this.fiveForces
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['threatNewEntrants', 'threatSubstitutes', 'bargainingPowerCustomers', 'bargainingPowerSuppliers', 'competitiveRivalry', ]
      ret = questionKeys.some(i => fiveForces[i])
       if(ret) return false

      return true
    },

    //PEST分析セクションの未入力チェック
    isEmpty_pest() {
      if(!this.pest) return true

      const pest = this.pest
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['pestPolitics', 'pestEconomy', 'pestSociety', 'pestTechnology', ]
      ret = questionKeys.some(i => pest[i])
       if(ret) return false

      return true
    },

    //4P4C分析セクションの未入力チェック
    isEmpty_fourPsFourCs() {
      if(!this.fourPsFourCs) return true

      const fourPsFourCs = this.fourPsFourCs
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['product', 'price', 'place', 'Promotion', 'customerValue', 'cost', 'convenience', 'communication']
      ret = questionKeys.some(i => fourPsFourCs[i])
       if(ret) return false

      return true
    },

    //4P競合比較分析セクションの未入力チェック
    isEmpty_fourPsComparison() {
      if(!this.fourPsComparison) return true

      const fourPsComparison = this.fourPsComparison
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['product', 'price', 'place', 'promotion', 'summary']
      ret = questionKeys.some(i => fourPsComparison[i])
      if(ret) return false

      const questionKeys2 = ['targetName', 'product', 'price', 'place', 'promotion']
      if(fourPsComparison.competitors) {
        ret = fourPsComparison.competitors.some(i => {
          return questionKeys2.some(j => i[j])
        })
      }
      if(ret) return false

      return true
    },

    //ビジネスモデルキャンバスセクションの未入力チェック
    isEmpty_businessModelCanvas() {
      if(!this.businessModelCanvas) return true

      const businessModelCanvas = this.businessModelCanvas
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['keyPartners', 'keyActivities', 'keyResources', 'valuePropositions', 'customerRelationships', 'customerSegments', 'channels', 'costStructure', 'revenueStreams']
      ret = questionKeys.some(i => businessModelCanvas[i])
       if(ret) return false

      return true
    },
  },

  watch: {
    vSwot(val) {
      //value変更で、各データを初期化
      this.swotData = val
      this.initSectionSwot(val);
    },
    vThreeCs(val) {
      //value変更で、各データを初期化
      this.threeCsData = val
      this.initSectionThreeCs(val);
    },
    vStp(val) {
      //value変更で、各データを初期化
      this.stpData = val
      this.initSectionStp(val);
    },
    vFiveForces(val) {
      //value変更で、各データを初期化
      this.fiveForcesData = val
      this.initSectionFiveForces(val);
    },
    vPest(val) {
      //value変更で、各データを初期化
      this.pestData = val
      this.initSectionPest(val);
    },
    vFourPsFourCs(val) {
      //value変更で、各データを初期化
      this.fourPsFourCsData = val
      this.initSectionFourPsFourCs(val);
    },
    vFourPsComparison(val) {
      //value変更で、各データを初期化
      this.fourPsComparisonData = val
      this.initSectionFourPsComparison(val);
    },
    vBusinessModelCanvas(val) {
      //value変更で、各データを初期化
      this.businessModelCanvasData = val
      this.initSectionBusinessModelCanvas(val);
    },
    swot: {
      //SWOT分析セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdateSwot = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_swot = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "swot.inputStatusSwot"(val) {
      this.$store.commit('strategy_input_status_swot', val)
    },
    threeCs: {
      //3C分析セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdateThreeCs = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_threeCs = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "threeCs.inputStatusThreeCs"(val) {
      this.$store.commit('strategy_input_status_threeCs', val)
    },
    stp: {
      //STP分析セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdateStp = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_stp = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "stp.inputStatusStp"(val) {
      this.$store.commit('strategy_input_status_stp', val)
    },
    fiveForces: {
      //5force分析セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdateFiveForces = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_fiveForces = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "fiveForces.inputStatusFiveForces"(val) {
      this.$store.commit('strategy_input_status_fiveForces', val)
    },
    pest: {
      //PEST分析セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdatePest = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_pest = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "pest.inputStatusPest"(val) {
      this.$store.commit('strategy_input_status_pest', val)
    },
    fourPsFourCs: {
      //4P4C分析セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdateFourPsFourCs = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_fourPsFourCs = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "fourPsFourCs.inputStatusFourPsFourCs"(val) {
      this.$store.commit('strategy_input_status_fourPsFourCs', val)
    },
    fourPsComparison: {
      //4P競合比較分析セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdateFourPsComparison = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_fourPsComparison = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "fourPsComparison.inputStatusFourPsComparison"(val) {
      this.$store.commit('strategy_input_status_fourPsComparison', val)
    },
    businessModelCanvas: {
      //ビジネスモデルキャンバスセクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdateBusinessModelCanvas = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_businessModelCanvas = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "businessModelCanvas.inputStatusBusinessModelCanvas"(val) {
      this.$store.commit('strategy_input_status_businessModelCanvas', val)
    },
  },
}
</script>
<style lang="scss" scoped>
  .bottommost-section {
    margin-bottom: 420px;
  }
  .strategy-contents-container{
    max-width: 1040px;
  }
</style>
