<template>
  <v-expansion-panels v-if="user" v-model="expansionPanelsValues" multiple tile>
    <v-expansion-panel>
      <div id="sdb-target-section"></div>
      <v-expansion-panel-header-with-label
        label="ターゲットユーザ"
        :status="target.inputStatusTarget"
        @finish="target.inputStatusTarget = $event ? 'done': isEmpty_target() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <target-user v-model="target" :readonly="!hasWritePermission || target.inputStatusTarget == 'done'"></target-user>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        id="sdb-persona-section"
        label="ペルソナ"
        :status="persona.inputStatusPersona"
        @finish="persona.inputStatusPersona = $event ? 'done': isEmpty_persona() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-row>
          <v-col>
            <persona-info v-model="persona" :readonly="!hasWritePermission || persona.inputStatusPersona == 'done'"></persona-info>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <sheet-info v-model="sheetInfo" class="bottommost-section" @mounted="sheetInfo_mounted = true"></sheet-info>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

import VExpansionPanelHeaderWithLabel from '../atoms/VExpansionPanelHeaderWithLabel';
import TargetUser from '../organisms/TargetUser';
import PersonaInfo from '../organisms/PersonaInfo';
import SheetInfo from '../organisms/SheetInfo';


export default {
  components: {
    VExpansionPanelHeaderWithLabel,
    TargetUser,
    PersonaInfo,
    SheetInfo,
  },

  props: ['value'],
  data() {
    return {
      expansionPanelsValues: [0, 1, 2],

      //### データ関連 ###
      dataInit: false,
      needUpdate: false,  //更新API呼出の要否フラグ

      user: null,  //このタブのメインデータ
      target: null, //ターゲットユーザセクションのデータ
      persona: null, //ペルソナセクションのデータ
      sheetInfo: null, //シートの作成者情報

      //### 未入力・編集中チェック ###
      timerObj: null, //タイマー実行のオブジェクト(タイマークリアの為、保持する)
      timerObj2: null, //タイマー実行のオブジェクト(データ初期化確認用)
      need_notInput_target: false,  //ターゲットユーザセクションの未入力・編集中チェック要否フラグ
      need_notInput_persona: false,  //ペルソナセクションの未入力・編集中チェック要否フラグ

      sheetInfo_mounted: false, 
    };
  },

  created() {
    //データ初期化
    if(this.value) {
      this.user = this.value
      this.initSectionData(this.value);
    }

    //未入力・編集中チェックタイマー登録
    this.timerObj = setInterval(() => {
      if(document.visibilityState != 'visible') return

      //ターゲットユーザセクションのチェック
      if(this.target && this.target.inputStatusTarget != 'done' && this.need_notInput_target) {

        if(this.isEmpty_target()) {
          if(this.target.inputStatusTarget != 'notInput')
            this.target.inputStatusTarget = 'notInput'
        } else {
          if(this.target.inputStatusTarget != 'edit')
            this.target.inputStatusTarget = 'edit'
        }
        this.need_notInput_target = false
      }

      //ペルソナセクションのチェック
      if(this.persona && this.persona.inputStatusPersona != 'done' && this.need_notInput_persona) {

        if(this.isEmpty_persona()) {
          if(this.persona.inputStatusPersona != 'notInput')
            this.persona.inputStatusPersona = 'notInput'
        } else {
          if(this.persona.inputStatusPersona != 'edit')
            this.persona.inputStatusPersona = 'edit'
        }
        this.need_notInput_persona = false
      }

    }, 1000)

    //データ初期化確認用タイマー
    this.timerObj2 = setInterval(() => {
      if(this.sheetInfo_mounted) {
        //更新者など描画完了で、データ初期化完了とする
        this.dataInit = true
        clearInterval(this.timerObj2);
      }
    }, 1000)
  },
  mounted() {
    //初期スクロール位置へ移動
    const initialScrollTarget = this.$store.getters.initialScrollTarget
    if(initialScrollTarget) {
      this.$vuetify.goTo(initialScrollTarget, {
        container: '#StrategyEdit_top',
        offset: 100,
        duration: 500
      })
      this.$store.commit('initialScrollTarget', null)
    }
  },
  beforeDestroy() {
    clearInterval(this.timerObj);
    if(this.timerObj2) clearInterval(this.timerObj2);
  },

  computed: {
    ...mapGetters([
      'hasWritePermission',
      'userInfo',
    ])
  },
  methods: {
    ...mapActions(['updateUser']),

    //デフォルトのサーバー同期処理
    updateDefault(force){
      if (force || this.needUpdate) {
        this.sheetInfo.updatedUser = this.userInfo.userId
        this.sheetInfo.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfo.updatedAt = new Date()

        this.updateUser(this.user)
        this.needUpdate = false
      }
    },
    finishAll() {
      this.target.inputStatusTarget = 'done'
      this.persona.inputStatusPersona = 'done'
    },

    //各セクションデータの初期化
    initSectionData(val) {
      if(val.target && Object.keys(val.target).length > 0) {
        this.target = val.target
      }
      if(val.persona && Object.keys(val.persona).length > 0) {
        this.persona = val.persona
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfo = val.sheetInfo
      }
    },

    //ターゲットユーザセクションの未入力チェック
    isEmpty_target() {
      if(!this.target) return true

      const target = this.target
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['mainDemogra', 'mainFeatures', 'mainNeeds', 'subDemogra', 'subFeatures', 'subNeeds']
      ret = questionKeys.some(i => target[i])
      if(ret) return false

      return true
    },

    //ペルソナセクションの未入力チェック
    isEmpty_persona() {
      if(!this.persona) return true

      const persona = this.persona
      let ret = false

      //各質問項目をチェック
      if(persona.data && persona.data.length > 0) {
        ret = persona.data.some(i => i.personaName || i.imageUrl || i.gender || i.age || i.familyStructure || i.job || i.incomeYear || i.residenceArea || i.transportationType || i.hobbies || i.senseOfValue || i.collectInfo || i.features || i.needs)
        if(ret) return false
      }

      return true
    },

  },

  watch: {
    value(val) {
      //value変更で、各データを初期化
      this.user = val
      this.initSectionData(val);
    },
    target: {
      //ターゲットユーザセクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_target = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "target.inputStatusTarget"(val) {
      this.$store.commit('user_input_status_target', val)
    },
    persona: {
      //ペルソナセクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_persona = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "persona.inputStatusPersona"(val) {
      this.$store.commit('user_input_status_persona', val)
    },
  },
}
</script>
<style lang="scss" scoped>
  .bottommost-section {
    margin-bottom: 420px;
  }
  .customer-journey-container{
    max-width: 100%;
  }
  .strategy-contents-container{
    max-width: 1040px;
  }
</style>
<style lang="scss">
.sheet-info-wrap{
    .head{
      font-weight: 600;
    }
  }
</style>