<template>
  <div class="pt-0 stp-analysis-wrap">
    <v-row class="align-center">
      <v-col cols="6">
        <v-textarea-with-link
          v-model="stp.stpSegmentation"
          label="顧客のセグメント定義"
          :readonly="readonly"
          reference="StpAnalysis_stpSegmentation.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
      <v-col cols="6">
        <v-textarea-with-link
          v-model="stp.stpTarget"
          label="どの顧客層を狙いたいか？"
          :readonly="readonly"
          reference="StpAnalysis_stpTarget.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col align="right" class="ma-0 pa-0">
        <input-sample class="input-sample" :refImg="true" reference="PositioningMap_image.jpg" label="ポジショニングマップ"></input-sample>
      </v-col>
    </v-row>
    <positioning-map-editor
      v-model="stp.stpPositioningmap"
      :sectionReadonly="readonly"
      class="mb-8"
    ></positioning-map-editor>
    <v-textarea
      v-model="stp.stpSummary"
      outlined
      auto-grow
      label="STP分析まとめ"
      :clearable="!readonly"
      :readonly="readonly"
    ></v-textarea>

  </div>
</template>
<script>
import PositioningMapEditor from './PositioningMapEditor.vue';
import InputSample from '../atoms/InputSample.vue';
import VTextareaWithLink from '../atoms/VTextareaWithLink.vue';

export default {
  components: {
    PositioningMapEditor,
    InputSample,
    VTextareaWithLink,
  },
  props: ['value', 'readonly'],
  data () {
    return {
      stp: {
        stpSegmentation: null,
        stpTarget: null,
        stpPositioningmap: null,
        stpSummary: null
      },
    }
  },
  created(){
    if(this.value) {
      this.stp = this.value
    }
  },
  watch: {
    value(val) {
      this.stp = val
    },
  }
}
</script>
<style lang="scss" scoped>
.stp-analysis-wrap {
  .input-sample{
    margin-top: -3px;
  }
}
</style>