<template>
  <div class="pt-0">
    <v-row>
      <v-col cols="12" md="6">
        <v-textarea-with-link
          v-model="threeCs.customerViewpoint"
          label="市場・顧客の観点"
          :readonly="readonly"
          class="mb-5"
          reference="ThreeCsAnalysis_customerViewpoint.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea-with-link
          v-model="threeCs.competitorViewpoint"
          label="競合の観点（競合分析内容のまとめ）"
          :readonly="readonly"
          class="mb-5"
          reference="ThreeCsAnalysis_competitorViewpoint.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea-with-link
          v-model="threeCs.companyViewpoint"
          label="自社の観点"
          :readonly="readonly"
          class="mb-5"
          reference="ThreeCsAnalysis_companyViewpoint.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea-with-link
          v-model="threeCs.threeCsSummary"
          label="3Cまとめ（顧客ニーズと競合との差別化ポイント整理）"
          :readonly="readonly"
          class="mb-5"
          reference="ThreeCsAnalysis_threeCsSummary.txt"
          :refDoc="true"
        ></v-textarea-with-link>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VTextareaWithLink from '../atoms/VTextareaWithLink.vue';

export default {
  components: {
    VTextareaWithLink,
  },
  props: ['value', 'readonly'],
  data () {
    return {
      threeCs: {
        customerViewpoint: null,
        competitorViewpoint: null,
        companyViewpoint: null,
        threeCsSummary: null
      },
    }
  },
  created(){
    if(this.value) {
      this.threeCs = this.value
    }
  },
  watch: {
    value(val) {
      this.threeCs = val
    },
  }
}
</script>