<template>
  <v-container class="pa-8">
    <v-row>
      <v-col class="borderd-col">
        <span class="text-h5 mr-1">Key Partners</span><p>主要パートナー</p>
        <v-textarea 
          v-model="businessModelCanvas.keyPartners"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
      <v-col>
	    <v-row>
          <v-col class="borderd-col">
            <span class="text-h5 mr-1">Key<br />Activities</span><p>主要な活動</p>
            <v-textarea 
              v-model="businessModelCanvas.keyActivities"
              auto-grow
              :clearable="!readonly"
              :readonly="readonly"
              flat
              solo
            ></v-textarea>
          </v-col>
        </v-row>
	    <v-row>
          <v-col class="borderd-col">
            <span class="text-h5 mr-1">Key Resources</span><p>主要な資源</p>
            <v-textarea 
              v-model="businessModelCanvas.keyResources"
              auto-grow
              :clearable="!readonly"
              :readonly="readonly"
              flat
              solo
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="borderd-col">
        <span class="text-h5 mr-1">Value Propositions</span><p>提供価値</p>
        <v-textarea 
          v-model="businessModelCanvas.valuePropositions"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
      <v-col>
	    <v-row>
          <v-col class="borderd-col">
            <span class="text-h5 mr-1">Customer<br />Relationships</span><p>顧客との関係</p>
            <v-textarea 
              v-model="businessModelCanvas.customerRelationships"
              auto-grow
              :clearable="!readonly"
              :readonly="readonly"
              flat
              solo
            ></v-textarea>
          </v-col>
        </v-row>
	    <v-row>
          <v-col class="borderd-col">
            <span class="text-h5 mr-1">Channels</span><p>チャネル/販路</p>
            <v-textarea 
              v-model="businessModelCanvas.channels"
              auto-grow
              :clearable="!readonly"
              :readonly="readonly"
              flat
              solo
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="borderd-col">
        <span class="text-h5 mr-1">Customer Segments</span><p>顧客セグメント</p>
        <v-textarea 
          v-model="businessModelCanvas.customerSegments"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="borderd-col">
        <span class="text-h5 mr-1">Cost Structure</span><p>コスト構造</p>
        <v-textarea 
          v-model="businessModelCanvas.costStructure"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
      <v-col class="borderd-col">
        <span class="text-h5 mr-1">Revenue Streams</span><p>収益の流れ</p>
        <v-textarea 
          v-model="businessModelCanvas.revenueStreams"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  props: ['value', 'readonly'],
  data () {
    return {
      businessModelCanvas: {
        keyPartners: null,
        keyActivities: null,
        keyResources: null,
        ValuePropositions: null,
        customerRelationships: null,
        channels: null,
        customerSegments: null,
        costStructure: null,
        revenueStreams: null
      },
    }
  },
  created(){
    if(this.value) {
      this.businessModelCanvas = this.value
    }
  },
  watch: {
    value(val) {
      this.businessModelCanvas = val
    },
  }
}
</script>
<style lang="scss" scoped>
.borderd-col {
  border: 1px black solid;
}
</style>
