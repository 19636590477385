<template>
  <v-expansion-panels v-if="brand" v-model="expansionPanelsVlues" multiple tile>
    <v-expansion-panel v-for="(def, i) in sectionDefinitions" :key="i">
      <v-expansion-panel-header-with-label
        :label="def.label"
        :status="brand[def.data][def.inputStatus]"
        @finish="finish($event, def)"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <a @click="cap(def.name)" download="abc.png" :href="fileImage" class="black--text">画像でクリップボードへコピー</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <BpSectionResolver
                :elementId="def.id"
                :name="def.name"
                :inputStatus="def.inputStatus"
                :ref="def.name" 
                v-model="brand[def.data]"
                :hasWritePermission="hasWritePermission"
                :dataInit="dataInit"
                @update="needUpdate = true"
              ></BpSectionResolver>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <sheet-info v-model="sheetInfo" class="bottommost-section mt-5" @mounted="sheetInfo_mounted = true"></sheet-info>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

import VExpansionPanelHeaderWithLabel from '../atoms/VExpansionPanelHeaderWithLabel';
import SheetInfo from '../organisms/SheetInfo';

import BpSectionResolver from '@/components/util/BpSectionResolver';

import html2canvas from 'html2canvas'

export default {
  components: {
    VExpansionPanelHeaderWithLabel,
    BpSectionResolver,
    SheetInfo,
  },

  props: ['value'],
  data() {
    return {
      expansionPanelsVlues: [],

      //### データ関連 ###
      dataInit: false,
      needUpdate: false,  //更新API呼出の要否フラグ

      brand: null,  //このタブのメインデータ
      sheetInfo: null, //シートの作成者情報

      //各セクションの定義
      sectionDefinitions: [
        {
          name: "ManegementPhilosophy",
          label: "経営理念",
          data: "philosophy",
          inputStatus: "inputStatusPhilosophy",
        },
        {
          name: "CompanyProfile",
          label: "医院に関する情報",
          data: "profile",
          inputStatus: "inputStatusClient",
        },
        {
          name: "CompanyVision",
          label: "ビジョン",
          data: "vision",
          inputStatus: "inputStatusVision",
        },
        {
          name: "CurrentStrategyAndAction",
          label: "現状の戦略と実施施策",
          data: "current",
          inputStatus: "inputStatusCurrent",
        },
        {
          name: "Service",
          label: "サービス内容",
          data: "service",
          inputStatus: "inputStatusService",
        },
        {
          id: "sdb-competitors-section",
          name: "Competitors",
          label: "競合分析",
          data: "competitors",
          inputStatus: "inputStatusCompetitive",
        },
        
      ],

      //### 未入力・編集中チェック ###
      timerObj: null, //タイマー実行のオブジェクト(タイマークリアの為、保持する)
      timerObj2: null, //タイマー実行のオブジェクト(データ初期化確認用)

      sheetInfo_mounted: false, 

      fileImage: null,
    };
  },

  created() {
    //セクションを全て展開状態に
    this.expansionPanelsVlues = this.sectionDefinitions.map((val, i) => i);

    //データ初期化
    if(this.value) {
      this.brand = this.value
      this.initSectionData(this.value);
    }

    //未入力・編集中チェックタイマー登録
    this.timerObj = setInterval(() => {
      if(document.visibilityState != 'visible') return

      this.sectionDefinitions.forEach(def => this.$refs[def.name][0].timeredCheck());

    }, 1000)

    //データ初期化確認用タイマー
    this.timerObj2 = setInterval(() => {
      if(this.sheetInfo_mounted) {
        //更新者など描画完了で、データ初期化完了とする
        this.dataInit = true
        clearInterval(this.timerObj2);
      }
    }, 1000)
  },
  mounted() {
    //初期スクロール位置へ移動
    const initialScrollTarget = this.$store.getters.initialScrollTarget
    if(initialScrollTarget) {
      this.$vuetify.goTo(initialScrollTarget, {
        container: '#StrategyEdit_top',
        offset: 100,
        duration: 500
      })
      this.$store.commit('initialScrollTarget', null)
    }
  },
  beforeDestroy() {
    clearInterval(this.timerObj);
    if(this.timerObj2) clearInterval(this.timerObj2);
  },

  computed: {
    ...mapGetters([
      'hasWritePermission',
      'userInfo',
    ]),
  },
  methods: {
    ...mapActions(['updateBrand']),

    //デフォルトのサーバー同期処理
    updateDefault(force){
      if (force || this.needUpdate) {
        this.sheetInfo.updatedUser = this.userInfo.userId
        this.sheetInfo.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfo.updatedAt = new Date()
        
        this.updateBrand(this.brand)
        this.needUpdate = false
      }
    },

    //入力ステータスを完了へ
    finish($event, def) {
      this.brand[def.data][def.inputStatus] = $event ? 'done': this.$refs[def.name][0].isEmpty() ? 'notInput' : 'edit';
    },

    //全ての入力ステータスを完了へ
    finishAll() {
      this.sectionDefinitions.forEach(def => this.brand[def.data][def.inputStatus] = 'done');
    },

    //各セクションデータの初期化
    initSectionData(val) {
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfo = val.sheetInfo
      }
    },

    async cap(refName) {
      this.fileImage = null;

      const canvasElement = await html2canvas(this.$refs[refName][0].$el).catch(e => {
        console.error(e);
        return;
      });

      if (!canvasElement) {
        return;
      }
      this.fileImage = canvasElement.toDataURL();

      canvasElement.toBlob(blob => {
        const item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]);
      });
    },
  },

  watch: {
    value(val) {
      //value変更で、各データを初期化
      this.brand = val
      this.initSectionData(val);
    },
  },
}
</script>
<style lang="scss" scoped>
  .bottommost-section {
    margin-bottom: 420px;
  }
  .strategy-contents-container{
    max-width: 1040px;
  }
</style>
<style lang="scss">
.sheet-info-wrap{
    .head{
      font-weight: 600;
    }
  }
</style>