<template>
  <v-app >
    <strategy-info-side-menu @change-tab="changeTab($event)"></strategy-info-side-menu>
    <v-main id="StrategyEdit_top" class="overflow-y-auto" :style="{height: 0, scrollBehavior: scrollBehavior}" v-scroll.self="onScroll">
      <strategy-info-topbar @save="save(null, true)" @finish="finish()"></strategy-info-topbar>
      <div class="pa-8 pt-5">
        <brand-panel ref="brand" v-if="selectedTab == 0" :value="brand"></brand-panel>
        <strategic-design ref="strategic" v-if="selectedTab == 1" :vSwot="swotData" :vThreeCs="threeCsData" :vStp="stpData" :vFiveForces="fiveForcesData" :vPest="pestData" :vFourPsFourCs="fourPsFourCsData" :vFourPsComparison="fourPsComparisonData" :vBusinessModelCanvas="businessModelCanvasData"></strategic-design>
        <user-insight ref="user" v-if="selectedTab == 2" :value="user"></user-insight>
        <design-requirements ref="design" v-if="selectedTab == 3" :value="design"></design-requirements>
        <planning-sheet ref="plan" v-if="selectedTab == 4" :value="plan" @move-other="moveOther"></planning-sheet>
      </div>

    </v-main>
    <bottom-panel @routing="handleRoutingEvent"></bottom-panel>
    <v-scroll-y-reverse-transition>
      <v-btn
        v-if="scrollTop > 50"
        color="rgba(13,71,161, 0.7)"
        absolute right bottom
        fab tile
        width="55"
        height="40"
        elevation="5"
        class="chevron-up-button mr-3 mb-10"
        @click="moveTop()"
      >
        <v-icon color="white">mdi-chevron-up</v-icon>
      </v-btn>
    </v-scroll-y-reverse-transition>

    <reference-document-dialog></reference-document-dialog>
    <reference-image-dialog></reference-image-dialog>

    <v-dialog v-model="confirm" max-width="340" persistent>
      <v-card class="pb-2">
        <v-card-text class="pb-1">
          <div class="px-3 py-5 text-center">
            保存されていない編集内容があります。<br>内容を保存して移動しますか？
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" depressed @click="confirmOK()">
            保存して移動
          </v-btn>
          <v-btn color="grey lighten-2" depressed @click="confirmCancel()">
            破棄して移動
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import StrategyInfoTopbar from '../components/atomic/organisms/StrategyInfoTopbar';
import ReferenceDocumentDialog from '../components/atomic/atoms/ReferenceDocumentDialog';
import ReferenceImageDialog from '../components/atomic/atoms/ReferenceImageDialog';
import StrategyInfoSideMenu from '../components/atomic/organisms/StrategyInfoSideMenu';
import BrandPanel from '../components/atomic/templates/BrandPanel';
import StrategicDesign from '../components/atomic/templates/StrategicDesign';
import UserInsight from '../components/atomic/templates/UserInsight';
import DesignRequirements from '../components/atomic/templates/DesignRequirements';
import PlanningSheet from '../components/atomic/templates/PlanningSheet';
import BottomPanel from '../components/atomic/organisms/BottomPanel';

export default {
  components: {
    StrategyInfoTopbar,
    ReferenceDocumentDialog,
    ReferenceImageDialog,
    StrategyInfoSideMenu,
    BrandPanel,
    StrategicDesign,
    UserInsight,
    DesignRequirements,
    PlanningSheet,
    BottomPanel
  },
  props: ['queryProjectId', 'readonly'],
  data() {
    return {
      selectedTab: -1,
      nextTab: 0,
      scrollTop: 0,
      scrollBehavior: 'smooth',

      confirm: false,
      confirm_callback: null,
      confirm_cancel_callback: null,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'initBrand', 'brand', 
      'initSwot', 'swotData', 
      'initThreeCs', 'threeCsData', 
      'initStp', 'stpData', 
      'initFiveForces', 'fiveForcesData', 
      'initPest', 'pestData', 
      'initFourPsFourCs', 'fourPsFourCsData', 
      'initFourPsComparison', 'fourPsComparisonData', 
      'initBusinessModelCanvas', 'businessModelCanvasData', 
      'initUser', 'user', 'design', 
      'initPlan', 'plan',
      'editablePositioningMap',
    ]),
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    //クリーンアップ
    window.removeEventListener("beforeunload", this.beforeunload);
  },
  methods: {
    ...mapActions([
      'getProject',
      'fetchBrand', 
      'fetchSwot', 
      'fetchThreeCs', 
      'fetchStp', 
      'fetchFiveForces', 
      'fetchPest', 
      'fetchFourPsFourCs', 
      'fetchFourPsComparison', 
      'fetchBusinessModelCanvas', 
      'fetchUser', 
      'fetchDesign', 
      'fetchPlan'
    ]),

    beforeunload(e) {
      const comp = this.getCurrentTabComponent();
      if(comp && comp.needUpdate) {
        e.returnValue = 'Check';
      }
    },
    async init() {
      console.log('this.readonly:' + this.readonly)
      await this.getProject(this.queryProjectId)

      this.$store.commit('project_readonly', this.readonly ? true : false)

      //参照したProjectIdをcookieへ保存
      if(this.project) {
        const cookieVal = this.$cookies.get('sdb_projectIdHist')
        const hist = cookieVal ? JSON.parse(cookieVal) : []
        
        if(hist.indexOf(this.project.id) < 0) {
          hist.unshift(this.project.id)

          this.$cookies.set('sdb_projectIdHist', JSON.stringify(hist.slice(0, 5)))
        }
      }

      //ブラウザクローズ時のハンドラ登録
      window.addEventListener("beforeunload", this.beforeunload);

      this.changeTab(0)
    },
    loadData() {
      //各タブ別にAPIからデータを取得
      //### 現在の挙動は、常に再検索 ###
      switch(this.selectedTab) {
      case 0:
        // if(!this.initBrand) {
        //   this.fetchBrand()
        // }
          this.fetchBrand()
        break;
      case 1:
        // if(!this.initStrategy) {
        //   this.fetchStrategy()
        // }
        this.fetchSwot()
        this.fetchThreeCs()
        this.fetchStp()
        this.fetchFiveForces()
        this.fetchPest()
        this.fetchFourPsFourCs()
        this.fetchFourPsComparison()
        this.fetchBusinessModelCanvas()
        break;
      case 2:
        // if(!this.initUser) {
        //   this.fetchUser()
        // }
        this.fetchUser()
        break;
      case 3:
        // if(!this.initDesign) {
        //   this.fetchDesign()
        // }
        this.fetchDesign()
        break;
      case 4:
        // if(!this.initPlan) {
        //   this.fetchPlan()
        // }
        this.fetchPlan()
        break;
      }
    },
    save(val, force) {
      //現在が戦略設計タブの場合、ポジショニングマップ保存をリクエストする
      if(this.selectedTab == 1 && this.editablePositioningMap) {
        this.$store.commit('setToast', {msg: 'ポジショニングマップの編集を終了してください', color: 'red', timeout: 4000});

        this.$vuetify.goTo("#sdb-stp-section", {
          container: '#StrategyEdit_top',
          offset: 100,
          duration: 500
        })

        return;
      }
      
      //各タブ別に保存
      let comp = val ? val : this.getCurrentTabComponent();
      if(comp) comp.updateDefault(force)
    },
    finish() {
      let comp = this.getCurrentTabComponent();
      if(comp) comp.finishAll()
    },
    moveOther(e) {
      this.scrollBehavior = "auto"
      setTimeout(() => {
        document.getElementById('StrategyEdit_top').scrollTop = 0;
        // this.$vuetify.goTo(0, {container: '#StrategyEdit_top', duration: 10})
        this.scrollBehavior = "smooth"

        setTimeout(() => {
          //別タブへ移動
          //selectorの指定あればstoreへセットする
          if(e.selector) {
            this.$store.commit('initialScrollTarget', e.selector)
          }

          this.changeTab(e.tab)
        }, 1);
      }, 1)
    },
    changeTab(e) {
      //### 現在の挙動は、OKキャンセルともにタブ移動させる ###
      if(this.selectedTab != e) {
        this.validateSaveOrCancel(
          () => {
            this.selectedTab = e
            this.loadData()
          },
          () => {
            this.selectedTab = e
            this.loadData()
          },
        )
      }
      //タブ移動時に開かれている入力サンプルを閉じる
      this.$store.commit('close_referenceDocument')
      this.$store.commit('close_referenceImage')
    },

    handleRoutingEvent(e) {
      this.validateSaveOrCancel(e, e)
    },

    validateSaveOrCancel(ok, cancel) {
      //現在が戦略設計タブの場合、ポジショニングマップ保存をリクエストする
      if(this.selectedTab == 1 && this.editablePositioningMap) {
        this.$store.commit('setToast', {msg: 'ポジショニングマップの編集を終了してください', color: 'red', timeout: 4000});

        this.$vuetify.goTo("#sdb-stp-section", {
          container: '#StrategyEdit_top',
          offset: 100,
          duration: 500
        })
        return;
      }

      //保存要否を確認、要ならconfirm表示
      const comp = this.getCurrentTabComponent();
      if(comp && comp.needUpdate) {
        this.confirm_callback = ok
        this.confirm_cancel_callback = cancel
        this.confirm = true
      } else {
        if(ok) ok(comp)
      }
    },
    confirmOK() {
      //OKなら保存してコールバック
      const comp = this.getCurrentTabComponent();
      this.save(comp)
      if(this.confirm_callback) this.confirm_callback(comp)
      this.confirm_callback = null
      this.confirm_cancel_callback = null
      this.confirm = false
    },
    confirmCancel() {
      //キャンセルは何もせずコールバック
      const comp = this.getCurrentTabComponent();
      if(this.confirm_cancel_callback) this.confirm_cancel_callback(comp)
      this.confirm_callback = null
      this.confirm_cancel_callback = null
      this.confirm = false
    },

    getCurrentTabComponent() {
      //現在のタブコンポーネント取得
      switch(this.selectedTab) {
      case 0:
        return this.$refs.brand
      case 1:
        return this.$refs.strategic
      case 2:
        return this.$refs.user
      case 3:
        return this.$refs.design
      case 4:
        return this.$refs.plan
      }
    },

    onScroll(e) {
      this.scrollTop = e.target.scrollTop
    },
    moveTop() {
      // this.$vuetify.goTo("#StrategyEdit_top")
      // this.$refs.StrategyEdit_main.scrollTop=0
      document.getElementById('StrategyEdit_top').scrollTop = 0;
    },

  },
}
</script>
<style lang="scss" scoped>
.v-main ::v-deep .v-main__wrap {
  height: fit-content;
}

.chevron-up-button {
  border-radius: 3px;
}
</style>
