<script>
import ManegementPhilosophy from '@/components/atomic/organisms/bp/ManegementPhilosophy';
import CompanyProfile from '@/components/atomic/organisms/bp/CompanyProfile';
import CompanyVision from '@/components/atomic/organisms/bp/CompanyVision';
import CurrentStrategyAndAction from '@/components/atomic/organisms/bp/CurrentStrategyAndAction';
import Service from '@/components/atomic/organisms/bp/Service';
import Competitors from '@/components/atomic/organisms/bp/Competitors';

export default {
  props: ['value', 'hasWritePermission', 'dataInit', 'elementId', 'name', 'inputStatus'], 

  data() {
    return {
      components: {
        ManegementPhilosophy,
        CompanyProfile,
        CompanyVision,
        CurrentStrategyAndAction,
        Service,
        Competitors,
      },

      el: null,
      need_notInput: false,  //未入力・編集中チェック要否フラグ
    }
  },

  render(h) {
    const comp = this.components[this.name];


    return this.el = h(comp, {
      props: {
        value: this.value,
        readonly: !this.hasWritePermission || this.value[this.inputStatus] == 'done',
        dataInit: this.dataInit
      },
      domProps: {
        id: this.elementId,
      },
      on: {
        input: (e) => this.$emit('input', e.target.value),
        update: (e) => this.$emit('update'),
      },
    });
  },

  methods: {
    timeredCheck() {
      if(this.el) {
        if(this.value && this.value[this.inputStatus] != 'done' && this.need_notInput) {

          if(this.isEmpty()) {
            if(this.value[this.inputStatus] != 'notInput')
              this.value[this.inputStatus] = 'notInput'
          } else {
            if(this.value[this.inputStatus] != 'edit')
              this.value[this.inputStatus] = 'edit'
          }
          this.need_notInput = false
        }
      }
    },
    isEmpty() {
      if(this.el) {
        return this.el.child.isEmpty();
      } else{
        return false;
      }
    },
  },
  watch: {
    value: {
      //経営理念セクション 変更時
      handler(){
        if(this.dataInit) {
          this.need_notInput = true  //未入力チェック必要、でフラグ立て
          this.$emit('update');
        }
      },
      deep: true,
    },
  },
}
</script>
