<template>
  <v-expansion-panels v-if="plan" v-model="expansionPanelsValues" multiple tile>
    <v-expansion-panel>
      <v-expansion-panel-header-with-label
        label="課題"
        :status="issue.inputStatusIssue"
        @finish="issue.inputStatusIssue = $event ? 'done': isEmpty_issue() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <!-- 課題 -->
              <planning-issue v-model="issue" :readonly="!hasWritePermission || issue.inputStatusIssue == 'done'"></planning-issue>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header-with-label
        label="目標"
        :status="objective.inputStatusObjective"
        @finish="objective.inputStatusObjective = $event ? 'done': isEmpty_objective() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col>
              <!-- 目標 -->
              <planning-objective v-model="objective" :readonly="!hasWritePermission || objective.inputStatusObjective == 'done'"></planning-objective>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header-with-label
        label="戦略整理"
        :status="reference.inputStatusReference"
        @finish="reference.inputStatusReference = $event ? 'done': isEmpty_reference() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container>
          <v-row>
            <v-col>
              <hearing-and-analysing-reference v-model="reference" :readonly="!hasWritePermission || reference.inputStatusReference == 'done'" class="strategy-contents-container mx-auto"></hearing-and-analysing-reference>
              <!-- ********** 戦略設計 ********** -->
              <!-- SWOT分析 -->
              <p class="ma-0 pb-0 pt-3 py-3 text-h5">強み・差別化ポイント</p>
              <v-divider class="reference-title-hr my-3"></v-divider>
              <div class="strategy-contents-container mx-auto">
                <swot-matrix v-model="swot.swotMatrix" :readonly="true"></swot-matrix>
                <div class="ma-0 pa-0 reference-link">
                  <a href="" @click.prevent.stop="moveOther(1, '#sdb-swot-section')">SWOT分析を編集する</a>
                </div>
              </div>

              <!-- ********** ユーザ理解 ********** -->
              <!-- ターゲットユーザ -->
              <p class="ma-0 pb-0 pt-3 py-3 text-h5">ターゲット</p>
              <v-divider class="reference-title-hr my-3"></v-divider>
              <div class="strategy-contents-container mx-auto">
                <target-user v-model="target" :readonly="true"></target-user>
                <div class="ma-0 pa-0 reference-link">
                  <a href="" @click.prevent.stop="moveOther(2, '#sdb-target-section')">ターゲット情報を編集する</a>
                </div>
              </div>

              <!-- ペルソナ -->
              <p class="ma-0 pb-0 pt-3 py-3 text-h5">ペルソナ</p>
              <v-divider class="reference-title-hr my-3"></v-divider>
              <persona-info v-model="persona" :readonly="true"></persona-info>
              <div class="ma-0 pa-0 reference-link">
                <a href="" @click.prevent.stop="moveOther(2, '#sdb-persona-section')">ペルソナ情報を編集する</a>
              </div>

              <!-- ********** BPシート ********** -->
              <!-- 競合 -->
              <p class="ma-0 pb-0 pt-3 py-3 text-h5">競合情報</p>
              <v-divider class="reference-title-hr my-3"></v-divider>
              <div class="strategy-contents-container mx-auto">
                <competitors v-model="competitors" :readonly="true"></competitors>
                <div class="ma-0 pa-0 reference-link">
                  <a href="" @click.prevent.stop="moveOther(0, '#sdb-competitors-section')">競合情報を編集する</a>
                </div>
              </div>

              <!-- ********** 戦略設計 ********** -->
              <!-- STP分析 -->
              <p class="ma-0 pb-0 pt-3 py-3 text-h5">STP</p>
              <v-divider class="reference-title-hr my-3"></v-divider>
              <div class="strategy-contents-container mx-auto">
                <positioning-map-editor v-model="stp.stpPositioningmap" class="mb-8" :sectionReadonly="true"></positioning-map-editor>
                <div class="ma-0 pa-0 reference-link">
                  <a href="" @click.prevent.stop="moveOther(1, '#sdb-stp-section')">STP分析を編集する</a>
                </div>
              </div>

            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header-with-label
        label="戦略まとめ"
        :status="plan.inputStatusPlan"
        @finish="plan.inputStatusPlan = $event ? 'done': isEmpty_plan() ? 'notInput' : 'edit'"
        :readonly="!hasWritePermission"
        :disabled="plan.summary && plan.summary.length < requirednum_plan_summary"
      ></v-expansion-panel-header-with-label>

      <v-expansion-panel-content>
        <v-container class="strategy-contents-container">
          <v-row>
            <v-col cols="12">
              <!-- 戦略まとめ -->
              <planning-summary v-model="plan.summary" :requirednum="requirednum_plan_summary" :readonly="!hasWritePermission || plan.inputStatusPlan == 'done'"></planning-summary>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <sheet-info v-model="sheetInfo" class="mb-8" @mounted="sheetInfo_mounted = true"></sheet-info>

    <!-- いいねボタン -->
    <div class="d-flex justify-end" style="width: 100%">
      <thumbs-up-input class="bottommost-section" :value="project"></thumbs-up-input>

    </div>


  </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

import VExpansionPanelHeaderWithLabel from '../atoms/VExpansionPanelHeaderWithLabel';
import PlanningIssue from '../organisms/PlanningIssue';
import PlanningObjective from '../organisms/PlanningObjective';
import HearingAndAnalysingReference from '../organisms/HearingAndAnalysingReference';
import PlanningSummary from '../organisms/PlanningSummary';
import SwotMatrix from '../molecules/SwotMatrix';
import TargetUser from '../organisms/TargetUser';
import PersonaInfo from '../organisms/PersonaInfo';
import PositioningMapEditor from '../organisms/PositioningMapEditor';
import Competitors from '@/components/atomic/organisms/bp/Competitors';
import ThumbsUpInput from '../molecules/ThumbsUpInput';
import SheetInfo from '../organisms/SheetInfo';

export default {
  components: {
    VExpansionPanelHeaderWithLabel,
    PlanningIssue,
    PlanningObjective,
    HearingAndAnalysingReference,
    PlanningSummary,
    SwotMatrix,
    TargetUser,
    PersonaInfo,
    PositioningMapEditor,
    Competitors,
    ThumbsUpInput,
    SheetInfo,
  },

  props: ['value'],
  data() {
    return {
      requirednum_plan_summary: 120, //戦略情報まとめの必要文字数

      expansionPanelsValues: [0, 1, 2, 3],

      //### データ関連 ###
      dataInit: false,
      needUpdate: false,  //更新API呼出の要否フラグ

      planinng: null,  //このタブのメインデータ
      issue: null, //課題セクションのデータ
      objective: null, //目標セクションのデータ
      reference: null, //戦略整理セクションのデータ
      plan: null, //戦略まとめセクションのデータ
      sheetInfo: null, //シートの作成者情報

      //### 参照専用データ ###
      //SWOT分析セクションのデータ
      swot: {
        swotMatrix: {
          swotStrengths: null,
          swotWeakness: null,
          swotOpportunity: null,
          swotThreat: null,
          swotStrengthsOpportunity: null,
          swotWeaknessOpportunity: null,
          swotStrengthsThreat: null,
          swotWeaknessThreat: null,
        },
        swotSummary: null,

        inputStatusSwot: '',
      },
      target: null, //ターゲットユーザセクションのデータ
      persona: null, //ペルソナセクションのデータ
      competitors: null, //競合分析セクションのデータ
      //STP分析セクションのデータ
      stp: {
        stpSegmentation: null,
        stpTarget: null,
        stpPositioningmap: null,
        stpSummary: null
      },

      //### 未入力・編集中チェック ###
      timerObj: null, //タイマー実行のオブジェクト(タイマークリアの為、保持する)
      timerObj2: null, //タイマー実行のオブジェクト(データ初期化確認用)
      need_notInput_issue: false,  //課題セクションの未入力・編集中チェック要否フラグ
      need_notInput_objective: false,  //目標セクションの未入力・編集中チェック要否フラグ
      need_notInput_reference: false,  //戦略整理セクションの未入力・編集中チェック要否フラグ
      need_notInput_plan: false,  //戦略まとめセクションの未入力・編集中チェック要否フラグ

      sheetInfo_mounted: false, 
    };
  },

  created() {
    //データ初期化
    if(this.value) {
      this.planinng = this.value
      this.initSectionData(this.value);
    }

    //未入力・編集中チェックタイマー登録
    this.timerObj = setInterval(() => {
      if(document.visibilityState != 'visible') return

      //課題セクションのチェック
      if(this.issue && this.issue.inputStatusIssue != 'done' && this.need_notInput_issue) {

        if(this.isEmpty_issue()) {
          if(this.issue.inputStatusIssue != 'notInput')
            this.issue.inputStatusIssue = 'notInput'
        } else {
          if(this.issue.inputStatusIssue != 'edit')
            this.issue.inputStatusIssue = 'edit'
        }
        this.need_notInput_issue = false
      }

      //目標セクションのチェック
      if(this.objective && this.objective.inputStatusObjective != 'done' && this.need_notInput_objective) {

        if(this.isEmpty_objective()) {
          if(this.objective.inputStatusObjective != 'notInput')
            this.objective.inputStatusObjective = 'notInput'
        } else {
          if(this.objective.inputStatusObjective != 'edit')
            this.objective.inputStatusObjective = 'edit'
        }
        this.need_notInput_objective = false
      }

      //戦略整理セクションのチェック
      if(this.reference && this.reference.inputStatusReference != 'done' && this.need_notInput_reference) {

        if(this.isEmpty_reference()) {
          if(this.reference.inputStatusReference != 'notInput')
            this.reference.inputStatusReference = 'notInput'
        } else {
          if(this.reference.inputStatusReference != 'edit')
            this.reference.inputStatusReference = 'edit'
        }
        this.need_notInput_reference = false
      }

      //戦略まとめセクションのチェック
      if(this.plan && this.plan.inputStatusPlan != 'done' && this.need_notInput_plan) {

        if(this.isEmpty_plan()) {
          if(this.plan.inputStatusPlan != 'notInput')
            this.plan.inputStatusPlan = 'notInput'
        } else {
          if(this.plan.inputStatusPlan != 'edit')
            this.plan.inputStatusPlan = 'edit'
        }
        this.need_notInput_plan = false
      }

    }, 1000)

    //データ初期化確認用タイマー
    this.timerObj2 = setInterval(() => {
      if(this.sheetInfo_mounted) {
        //更新者など描画完了で、データ初期化完了とする
        this.dataInit = true
        clearInterval(this.timerObj2);
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timerObj);
    if(this.timerObj2) clearInterval(this.timerObj2);
  },
  computed: {
    ...mapGetters([
      'project', 'hasWritePermission',
      'userInfo',
    ])
  },
  methods: {
    ...mapActions(['updatePlan']),

    moveOther(tab, selector) {
      this.$emit('move-other', {tab, selector})
    },

    //デフォルトのサーバー同期処理
    updateDefault(force){
      if (force || this.needUpdate) {
        this.sheetInfo.updatedUser = this.userInfo.userId
        this.sheetInfo.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfo.updatedAt = new Date()

        this.updatePlan(this.planinng)
        this.needUpdate = false
      }
    },
    finishAll() {
      this.issue.inputStatusIssue = 'done'
      this.objective.inputStatusObjective = 'done'
      this.reference.inputStatusReference = 'done'

      if(this.plan.summary.length >= this.requirednum_plan_summary) {
        this.plan.inputStatusPlan = 'done'
      }
    },

    //各セクションデータの初期化
    initSectionData(val) {
      //入力項目
      if(val.issue && Object.keys(val.issue).length > 0) {
        this.issue = val.issue
      }
      if(val.objective && Object.keys(val.objective).length > 0) {
        this.objective = val.objective
      }
      if(val.reference && Object.keys(val.reference).length > 0) {
        this.reference = val.reference
      }
      if(val.plan && Object.keys(val.plan).length > 0) {
        this.plan = val.plan
      }

      //参照項目
      if(val.swot && Object.keys(val.swot).length > 0) {
        this.swot = val.swot
      }
      if(val.target && Object.keys(val.target).length > 0) {
        this.target = val.target
      }
      if(val.persona && Object.keys(val.persona).length > 0) {
        this.persona = val.persona
      }
      if(val.competitors && Object.keys(val.competitors).length > 0) {
        this.competitors = val.competitors
      }
      if(val.stp && Object.keys(val.stp).length > 0) {
        this.stp = val.stp
      }

      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfo = val.sheetInfo
      }
    },

    //課題セクションの未入力チェック
    isEmpty_issue() {
      if(!this.issue) return true

      const issue = this.issue
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['extraction']
      ret = questionKeys.some(i => issue[i])
      if(ret) return false
      return true
    },

    //目標セクションの未入力チェック
    isEmpty_objective() {
      if(!this.objective) return true

      const objective = this.objective
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['quantification','qualification']
      ret = questionKeys.some(i => objective[i])
      if(ret) return false

      return true
    },

    //戦略整理セクションの未入力チェック
    isEmpty_reference() {
      if(!this.reference) return true

      const reference = this.reference
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['concept','vision','tagline']
      ret = questionKeys.some(i => reference[i])
      if(ret) return false

      return true
    },

    //戦略まとめ情報セクションの未入力チェック
    isEmpty_plan() {
      if(!this.plan) return true

      const plan = this.plan
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['summary']
      ret = questionKeys.some(i => plan[i])
      if(ret) return false

      return true
    },
  },

  watch: {
    value(val) {
      //value変更で、各データを初期化
      this.planinng = val
      this.initSectionData(val);
    },
    issue: {
      //課題セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_issue = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "issue.inputStatusIssue"(val) {
      this.$store.commit('plan_input_status_issue', val)
    },
    objective: {
      //目標セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_objective = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "objective.inputStatusObjective"(val) {
      this.$store.commit('plan_input_status_objective', val)
    },
    reference: {
      //戦略整理セクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_reference = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "reference.inputStatusReference"(val) {
      this.$store.commit('plan_input_status_reference', val)
    },
    plan: {
      //戦略まとめセクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true  //更新API呼出必要、でフラグ立て
          this.need_notInput_plan = true  //未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "plan.inputStatusPlan"(val) {
      this.$store.commit('plan_input_status_plan', val)
    },
  },
}
</script>
<style lang="scss" scoped>
  .bottommost-section {
    margin-bottom: 420px;
  }
  .reference-link {
    font-size: 0.85em;
    text-align: right;
  }
  .reference-title-hr {
    background-color: #1565C0;
  }
  .strategy-contents-container{
    max-width: 1040px;
  }
</style>
<style lang="scss">
.sheet-info-wrap{
    .head{
      font-weight: 600;
    }
  }
</style>