<template>
  <v-banner v-if="project" sticky mobile-breakpoint="1" class="bg-banner d-flex" style="z-index:2">

    <div class="d-flex overflow-x-auto">

      <div class="mr-8">
        <div class="text-subtitle-1 white--text" style="width: max-content;">{{project.projectName}}</div>
        <div class="text-overline white--text" style="width: max-content;">{{project.clientName}}</div>
      </div>

      <project-participants></project-participants>
    </div>
    <template v-slot:actions>
      <div class="d-flex justify-end">
        <v-icon color="white" size="26" class="pl-3 py-1">mdi-thumb-up</v-icon>
        <span class="pr-3 pl-2 white--text text-h6 font-weight-bold">{{
          howNiceCount
        }}</span>
      </div>
      <v-btn depressed color="indigo darken-1" dark :width="120" rounded @click="$emit('finish')">
        <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
        すべて完了
      </v-btn>
      <v-btn depressed color="#ffa000" dark :width="120" rounded @click="$emit('save')" >
        <v-icon>mdi-content-save</v-icon>
        保存
      </v-btn>
    </template>
  </v-banner>
</template>
<script>
import { mapGetters } from 'vuex'

import ProjectParticipants from '../organisms/ProjectParticipants'

export default {
  components: {
    ProjectParticipants,
  },
  computed: {
    ...mapGetters([
      'project', 'howNiceCount',
    ]),
  },
}
</script>
<style lang="scss" scoped>
.bg-banner{
  background-color: rgba(13,71,161, 0.7)!important;

  & ::v-deep .v-banner__wrapper {
    width: 100%;
    .v-banner__actions {
      margin-left: 0;
    }
  }
}
</style>
