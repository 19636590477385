<template>
  <div class="pt-0 swot-analysis-wrap">
    <v-row class="ma-0 pa-0">
      <v-col align="right" class="ma-0 pa-0 pb-2">
        <input-sample class="input-sample" :refDoc="true" reference="SwotAnalysis_swotMatrix.txt" label="SWOT分析"></input-sample>
      </v-col>
    </v-row>

    <swot-matrix v-model="swotItems.swotMatrix" :readonly="readonly"></swot-matrix>

    <v-textarea-with-link
      v-model="swotItems.swotSummary"
      :readonly="readonly"
      label="SWOT分析まとめ"
      reference="SwotAnalysis_swotSummary.txt"
      :refDoc="true"
    ></v-textarea-with-link>
  </div>
</template>
<script>
import SwotMatrix from '../molecules/SwotMatrix';
import InputSample from '../atoms/InputSample.vue';
import VTextareaWithLink from '../atoms/VTextareaWithLink.vue';

export default {
  components: {
    SwotMatrix,
    InputSample,
    VTextareaWithLink,
  },
  props: ['value', 'readonly'],
  data () {
    return {
      swotItems: {
        swotMatrix: {
          swotStrengths: null,
          swotWeakness: null,
          swotOpportunity: null,
          swotThreat: null,
          swotStrengthsOpportunity: null,
          swotWeaknessOpportunity: null,
          swotStrengthsThreat: null,
          swotWeaknessThreat: null,
        },
        swotSummary: null,

        inputStatusSwot: '',
      },
    }
  },
  created(){
    if(this.value){
      this.swotItems = this.value
    }
  },
  methods: {
  },
  watch: {
    value(val){
      this.swotItems = val
    }
  }
}
</script>
<style lang="scss" scoped>
.swot-analysis-wrap {

}
</style>