<template>
  <div v-if="design">
    <v-expansion-panels :value="openPanels" multiple tile>

      <!-- デザインイメージ -->
      <v-expansion-panel>
        <v-expansion-panel-header-with-label
          label="デザインイメージ"
          :status="inputStatusDesign"
          @finish="didCloseImageSection($event)"
          :readonly="!hasWritePermission"
        ></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <v-container class="strategy-contents-container">
            <design-image v-model="image" :readonly="!hasWritePermission || image.inputStatusDesign == 'done'"></design-image>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- デザインパラメータ -->
      <v-expansion-panel>
        <v-expansion-panel-header-with-label
          label="デザインパラメータ"
          :status="inputStatusParameters"
          @finish="didCloseParameterSection($event)"
          :readonly="!hasWritePermission"
        ></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <v-container class="strategy-contents-container">
            <design-parameter v-model="parameter" :readonly="!hasWritePermission || parameter.inputStatusParameters == 'done'"></design-parameter>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- 参考サイト -->
      <v-expansion-panel>
        <v-expansion-panel-header-with-label
          label="参考サイト"
          :status="inputStatusReferencesite"
          @finish="didCloseReferenceSection($event)"
          :readonly="!hasWritePermission"
        ></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <v-container class="strategy-contents-container">
            <reference-sites v-model="reference" :readonly="!hasWritePermission || reference.inputStatusReferencesite == 'done'"></reference-sites>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- デザイン要件備考 -->
      <v-expansion-panel>
        <v-expansion-panel-header-with-label
          label="デザイン要件備考"
          :status="inputStatusNote"
          @finish="didCloseNoteSection($event)"
          :readonly="!hasWritePermission"
        ></v-expansion-panel-header-with-label>
        <v-expansion-panel-content>
          <v-container class="strategy-contents-container">
            <design-note v-model="note" :readonly="!hasWritePermission || note.inputStatusNote == 'done'"></design-note>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
    <div class="d-flex justify-center py-5">
      <sheet-info v-model="sheetInfo" class="bottommost-section" @mounted="sheetInfo_mounted = true"></sheet-info>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

import VExpansionPanelHeaderWithLabel from '../atoms/VExpansionPanelHeaderWithLabel';
import DesignImage from '../organisms/DesignImage'
import DesignParameter from '../organisms/DesignParameter'
import ReferenceSites from '../organisms/ReferenceSites'
import DesignNote from '../organisms/DesignNote'
import SheetInfo from '../organisms/SheetInfo';

export default {
  components: {
    VExpansionPanelHeaderWithLabel,
    DesignImage,
    DesignParameter,
    ReferenceSites,
    DesignNote,
    SheetInfo,
  },

  props: ['value'],
  data() {
    return {
      openPanels: [0, 1, 2, 3],

      //### データ関連 ###
      dataInit:   false,
      needUpdate: false,  // 更新API呼出の要否フラグ

      design:     null,   // このタブのメインデータ
      image:      null,   // デザインイメージセクションのデータ
      reference:  null,   // 参考サイトセクションのデータ
      parameter:  null,   // デザインパラメータセクションのデータ
      note:       null,   // デザイン要件備考セクションのデータ
      sheetInfo:  null,   // シートの作成者情報

      //### 未入力・編集中チェック ###
      timerObj: null,                 // タイマー実行のオブジェクト(タイマークリアの為、保持する)
      timerObj2: null, //タイマー実行のオブジェクト(データ初期化確認用)
      need_notInput_image: false,     // デザインイメージセクションの未入力・編集中チェック要否フラグ
      need_notInput_reference: false, // 参考サイトセクションの未入力・編集中チェック要否フラグ
      need_notInput_parameter: false, // デザインパラメータセクションの未入力・編集中チェック要否フラグ
      need_notInput_note: false,      // デザイン要件備考セクションの未入力・編集中チェック要否フラグ

      sheetInfo_mounted: false, 
    };
  },

  created() {
    // データ初期化
    if(this.value) {
      this.design = this.value
      this.initSectionData(this.value);
    }

    // 未入力・編集中チェックタイマー登録
    this.timerObj = setInterval(() => {
      if(document.visibilityState != 'visible') return

      // デザインイメージセクションのチェック
      if(this.image && this.image.inputStatusDesign != 'done' && this.need_notInput_image) {

        if(this.isEmpty_image()) {
          if(this.image.inputStatusDesign != 'notInput')
            this.image.inputStatusDesign = 'notInput'
        } else {
          if(this.image.inputStatusDesign != 'edit')
            this.image.inputStatusDesign = 'edit'
        }
        this.need_notInput_image = false
      }

      // 参考サイトセクションのチェック
      if(this.reference && this.reference.inputStatusReferencesite != 'done' && this.need_notInput_reference) {

        if(this.isEmpty_reference()) {
          if(this.reference.inputStatusReferencesite != 'notInput')
            this.reference.inputStatusReferencesite = 'notInput'
        } else {
          if(this.reference.inputStatusReferencesite != 'edit')
            this.reference.inputStatusReferencesite = 'edit'
        }
        this.need_notInput_reference = false
      }

      // デザインパラメータセクションのチェック
      if(this.parameter && this.parameter.inputStatusParameters != 'done' && this.need_notInput_parameter) {

        if(this.isEmpty_parameter()) {
          if(this.parameter.inputStatusParameters != 'notInput')
            this.parameter.inputStatusParameters = 'notInput'
        } else {
          if(this.parameter.inputStatusParameters != 'edit')
            this.parameter.inputStatusParameters = 'edit'
        }
        this.need_notInput_parameter = false
      }

      // デザイン要件備考セクションのチェック
      if(this.note && this.note.inputStatusNote != 'done' && this.need_notInput_note) {

        if(this.isEmpty_note()) {
          if(this.note.inputStatusNote != 'notInput')
            this.note.inputStatusNote = 'notInput'
        } else {
          if(this.note.inputStatusNote != 'edit')
            this.note.inputStatusNote = 'edit'
        }
        this.need_notInput_note = false
      }
    }, 1000)

    //データ初期化確認用タイマー
    this.timerObj2 = setInterval(() => {
      if(this.sheetInfo_mounted) {
        //更新者など描画完了で、データ初期化完了とする
        this.dataInit = true
        clearInterval(this.timerObj2);
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timerObj)
    if(this.timerObj2) clearInterval(this.timerObj2);
  },

  methods: {
    ...mapActions(['updateDesign']),

    //デフォルトのサーバー同期処理
    updateDefault(force){
      if (force || this.needUpdate) {
        this.sheetInfo.updatedUser = this.userInfo.userId
        this.sheetInfo.updatedUserName = (this.userInfo.lastName ?? "") + " " + (this.userInfo.firstName ?? "")
        this.sheetInfo.updatedAt = new Date()

        //各コンポーネントが別インスタンスを返す実装の為、再設定
        this.design.image = this.image
        this.design.referencesite = this.reference
        this.design.parameter = this.parameter
        this.design.note = this.note

        this.updateDesign(this.design)
        this.needUpdate = false
      }
    },

    // すべて完了
    finishAll() {
      this.image.inputStatusDesign            = 'done'
      this.reference.inputStatusReferencesite = 'done'
      this.parameter.inputStatusParameters    = 'done'
      this.note.inputStatusNote               = 'done'
    },

    //各セクションデータの初期化
    initSectionData(val) {
      if(val.image && Object.keys(val.image).length > 0) {
        this.image = val.image
      }
      if(val.referencesite && Object.keys(val.referencesite).length > 0) {
        this.reference = val.referencesite
      }
      if(val.parameter && Object.keys(val.parameter).length > 0) {
        this.parameter = val.parameter
      }
      if(val.note && Object.keys(val.note).length > 0) {
        this.note = val.note
      }
      if(val.sheetInfo && Object.keys(val.sheetInfo).length > 0) {
        this.sheetInfo = val.sheetInfo
      }
    },

    // デザインイメージセクションを閉じた場合
    didCloseImageSection(event) {
      if (event) {
        this.image.inputStatusDesign = 'done'
      } else {
        this.image.inputStatusDesign = this.isEmpty_image() ? 'notInput' : 'edit'
      }
    },

    // デザインパラメータセクションを閉じた場合
    didCloseParameterSection(event) {
      if (event) {
        this.parameter.inputStatusParameters = 'done'
      } else {
        this.parameter.inputStatusParameters = this.isEmpty_parameter() ? 'notInput' : 'edit'
      }
    },

    // 参考サイトセクションを閉じた場合
    didCloseReferenceSection(event) {
      if (event) {
        this.reference.inputStatusReferencesite = 'done'
      } else {
        this.reference.inputStatusReferencesite = this.isEmpty_reference() ? 'notInput' : 'edit'
      }
    },

    // デザイン要件備考セクションを閉じた場合
    didCloseNoteSection(event) {
      if (event) {
        this.note.inputStatusNote = 'done'
      } else {
        this.note.inputStatusNote = this.isEmpty_note() ? 'notInput' : 'edit'
      }
    },

    // デザインイメージセクションの未入力チェック
    isEmpty_image() {
      if(!this.image) return true

      // ロゴ
      if ( this.image.logo
        && (this.image.logo.logoUrl || this.image.logo.logoMeaning)) {
        return false
      }
      // 医院カラー（カラー）
      if ( this.image.mainColor
        || this.image.sub1Color
        || this.image.sub2Color
        || this.image.sub3Color) {
        return false
      }
      // 医院内装（参考画像）
      if (this.image.referenceImages && this.image.referenceImages.length) return false
      // テイスト・こだわり（説明）
      if (this.image.taste) return false
      // フォントイメージ
      if (this.image.font && this.image.font.length) return false
      // デザインキーワード
      if (this.image.designKeyword && this.image.designKeyword.length) return false

      return true
    },

    // デザインパラメータセクションの未入力チェック
    isEmpty_parameter() {
      if(!this.parameter) return true

      if ( this.parameter.parameters
        && this.parameter.parameters.length
        && this.parameter.parameters.some(param => param !== null && param !== undefined)) {
        return false
      }

      return true
    },

    // 参考サイトセクションの未入力チェック
    isEmpty_reference() {
      if(!this.reference) return true

      if ( this.reference.data.some(ref => ref.siteName)
        || this.reference.data.some(ref => ref.siteUrl)
        || this.reference.data.some(ref => ref.referencePoint)) {
        return false
      }

      return true
    },

    // デザイン要件備考セクションの未入力チェック
    isEmpty_note() {
      if(!this.note) return true

      if ( this.note.designNote
        && this.note.designNote.length) {
          return false
        }

      return true
    },
  },

  computed: {
    ...mapGetters([
      'hasWritePermission',
      'userInfo',
    ]),

    // デザインイメージセクションの入力状態
    inputStatusDesign() {
      return this.image ? this.image.inputStatusDesign : null
    },

    // デザインパラメータセクションの入力状態
    inputStatusParameters() {
      return this.parameter ? this.parameter.inputStatusParameters : null
    },

    // 参考サイトセクションの入力状態
    inputStatusReferencesite() {
      return this.reference ? this.reference.inputStatusReferencesite : null
    },

    // デザイン要件備考セクションの入力状態
    inputStatusNote() {
      return this.note ? this.note.inputStatusNote : null
    },
  },

  watch: {
    value(val) {
      //value変更で、各データを初期化
      this.design = val
      this.initSectionData(val);
    },
    image: {
      // デザインイメージセクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true            // 更新API呼出必要、でフラグ立て
          this.need_notInput_image = true   // 未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "image.inputStatusDesign"(val) {
      this.$store.commit('design_input_status_design', val)
    },
    parameter: {
      // デザインイメージセクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true              // 更新API呼出必要、でフラグ立て
          this.need_notInput_parameter = true // 未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "parameter.inputStatusParameters"(val) {
      this.$store.commit('design_input_status_parameters', val)
    },
    reference: {
      // デザインイメージセクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true              // 更新API呼出必要、でフラグ立て
          this.need_notInput_reference = true // 未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "reference.inputStatusReferencesite"(val) {
      this.$store.commit('design_input_status_referencesite', val)
    },
    note: {
      // デザインイメージセクション 変更時
      handler(){
        if(this.dataInit) {
          this.needUpdate = true            // 更新API呼出必要、でフラグ立て
          this.need_notInput_note = true   // 未入力チェック必要、でフラグ立て
        }
      },
      deep: true,
    },
    "note.inputStatusNote"(val) {
      this.$store.commit('design_input_status_note', val)
    },
  },
}
</script>

<style lang="scss" scoped>
  .strategy-contents-container{
    max-width: 1040px;
  }
</style>
