<template>
  <v-container class="pa-8">
    <v-row>
      <v-col class="four-ps-comparison__fixed-col"></v-col>
      <v-col align="center">自社</v-col>
      <v-col align="center">競合①</v-col>
      <v-col align="center">競合②</v-col>
    </v-row>
    <v-row>
      <v-col class="four-ps-comparison__fixed-col"></v-col>
      <v-col align="center">{{project.clientName}}</v-col>
      <v-col class="four-ps-comparison__borderd-col" v-bind:class="{ 'four-ps-comparison__borderd-col-right': index==fourPsComparison.competitors.length-1}" v-for="(competitor, index) in fourPsComparison.competitors" :key="competitor.fourPsCompetitiveAnalysisId">
        <v-text-field
          v-model="competitor.targetName"
          :clearable="!readonly"
          :readonly="readonly"
          hide-details
          flat
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="four-ps-comparison__fixed-col four-ps-comparison__borderd-col"><div align="center"><p>Product</p>商品・サービス</div></v-col>
      <v-col class="four-ps-comparison__borderd-col">
        <v-textarea 
          v-model="fourPsComparison.product"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
      <v-col class="four-ps-comparison__borderd-col" v-bind:class="{ 'four-ps-comparison__borderd-col-right': index==fourPsComparison.competitors.length-1}" v-for="(competitor, index) in fourPsComparison.competitors" :key="competitor.fourPsCompetitiveAnalysisId">
        <v-textarea 
          v-model="competitor.product"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="four-ps-comparison__fixed-col four-ps-comparison__borderd-col"><div align="center"><p>Price</p>価格帯</div></v-col>
      <v-col class="four-ps-comparison__borderd-col">
        <v-textarea 
          v-model="fourPsComparison.price"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
      <v-col class="four-ps-comparison__borderd-col" v-bind:class="{ 'four-ps-comparison__borderd-col-right': index==fourPsComparison.competitors.length-1}" v-for="(competitor, index) in fourPsComparison.competitors" :key="competitor.fourPsCompetitiveAnalysisId">
        <v-textarea 
          v-model="competitor.price"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="four-ps-comparison__fixed-col four-ps-comparison__borderd-col"><div align="center"><p>Place</p>立地・流通方法</div></v-col>
      <v-col class="four-ps-comparison__borderd-col">
        <v-textarea 
          v-model="fourPsComparison.place"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
      <v-col class="four-ps-comparison__borderd-col" v-bind:class="{ 'four-ps-comparison__borderd-col-right': index==fourPsComparison.competitors.length-1}" v-for="(competitor, index) in fourPsComparison.competitors" :key="competitor.fourPsCompetitiveAnalysisId">
        <v-textarea 
          v-model="competitor.place"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col class="four-ps-comparison__fixed-col four-ps-comparison__borderd-col-bottom"><div align="center"><p>Promotion</p>プロモーション</div></v-col>
      <v-col class="four-ps-comparison__borderd-col-bottom">
        <v-textarea 
          v-model="fourPsComparison.promotion"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
      <v-col class="four-ps-comparison__borderd-col-bottom" v-bind:class="{ 'four-ps-comparison__borderd-col-right': index==fourPsComparison.competitors.length-1}" v-for="(competitor, index) in fourPsComparison.competitors" :key="competitor.fourPsCompetitiveAnalysisId">
        <v-textarea 
          v-model="competitor.promotion"
          auto-grow
          :clearable="!readonly"
          :readonly="readonly"
          flat
          solo
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea 
          v-model="fourPsComparison.summary"
          outlined
          auto-grow
          label="4P分析まとめ"
          :clearable="!readonly"
          :readonly="readonly"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: ['value', 'readonly'],
  data () {
    return {
      fourPsComparison: {
        product: null,
        price: null,
        place: null,
        promotion: null,
        competitors: [
          {
            targetName: null,
            product: null,
            price: null,
            place: null,
            promotion: null,
          },
          {
            targetName: null,
            product: null,
            price: null,
            place: null,
            promotion: null,
          }
        ],
        summary: null,
      },
    }
  },
  computed: {
    ...mapGetters(['project']),
  },
  created(){
    if(this.value) {
      this.fourPsComparison = this.value
    }
  },
  watch: {
    value(val) {
      this.fourPsComparison = val
    },
  }
}
</script>
<style lang="scss" scoped>
.four-ps-comparison {

  &__borderd-col {
    border: black solid;
    border-width: 1px 0 0 1px;
  }

  &__borderd-col-bottom {
    border: black solid;
    border-width: 1px 0 1px 1px;
  }

  &__borderd-col-right {
    border-right: 1px black solid;
  }

  &__fixed-col {
    width: 200px;
    min-width: 200px;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
